.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #36A1D4;
  height:4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Map-container {
  height:700px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'K2D', monserrat, DIN, barlow, sans-serif;
}

.logo {
  display: inline-flex;
  padding-top: 15px;
  padding-left: 10px;
}

.page {
  display:flex;
  flex-flow:row wrap;
  /* flex-direction:row; */
  justify-content:center;
  margin-top:4em;
  cursor: default;
}

#stretch{
  flex: 1 1 auto;
  max-width: 30em;
  min-width: 1em;
}

form {
  position:relative;
  height:27em;
  font-family: 'K2D', monserrat, DIN, barlow, sans-serif;
  color:#36A1D4;
  font-size: 16pt;
  text-align:left;
  margin:2em;
  flex-shrink:2;
  flex-basis:auto;
  min-width:10em;
  max-width:24em;
  padding-top:1em;
}

.boxes{
  display:inline-flex;
  height:40px;
  align-items: center;
}

.border{
  margin-bottom:4px !important;
}


#name{
  font-family: 'K2D', monserrat, DIN, barlow, sans-serif;
  color:#36A1D4;
  width:150px;
  padding:7px !important;
  border:2px solid #36A1D4;
  margin:8px;
  margin-left:2px;
  right:0;
  top:25%;
  border-radius: 5px;
}

#name:focus{
  border:4px solid #36A1D4;
  outline:none;
}

#number{

  font-family: 'K2D', monserrat, DIN, barlow, sans-serif;
  color:#36A1D4;
  padding:7px !important;
  width:150px;
  border:2px solid #36A1D4;
  margin:8px;
  right:0;
  top:35%;
  border-radius: 5px;
}

#number:focus{
  border:4px solid #36A1D4;
  outline:none;
}
::placeholder{
  font-family: 'K2D', monserrat, DIN, barlow, sans-serif;
  color:#36A1D4;
  font-size:12pt;
}

img.small {
  display: none;
}

span.thisflyer {
  text-decoration: underline;
}

span.thisflyer:hover ~ img {
  display: block;
  position:absolute;
  width: 500px;
  left: -290px;
  bottom: -20px;
  cursor:pointer;
}


span.thisflyer:hover {
  cursor: pointer;
  text-decoration: underline;
}

.details-section {
  display: flex;
  flex-direction: column;
}

.details-item {
  padding: 12px 0;
}
.print-button {
  text-decoration: none;
  background-color: #36A1D4;
  font-family: 'K2D', monserrat, DIN, barlow, sans-serif;
  font-size: 20pt;
  color: white;
  width: 160px;
  margin-top: 12px;
  padding-top: 5px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  border:none;
  cursor: pointer;
}

.print-button-disabled {
  background-color: rgba(54, 162, 212, 0.274);
  pointer-events:none;
}

input:disabled {
  background-color: rgba(54, 162, 212, 0.274);
  pointer-events:none;
}


.pdf:hover{
  box-shadow: 0 6px 8px 0 rgba(0,0,0,0.24), 0 12px 16px 0 rgba(0,0,0,0.19);
  outline:none;
}
.pdf:focus{
  outline:none;
}

.print-hidden{

  display: none;
}

input.check1{
  position:absolute;
  opacity:0.5;
  width:30px;
  height:30px;
  color:#36A1D4;
  outline: 4px solid rgba(54, 161, 212, 1);
  cursor:pointer;
  margin:20px;
  margin-top:10px;
  top:0;

}
input.check1 :checked ~ .checkmark{
  background:#36A1D4;
  color:#36A1D4;
}
input.check2{
  color:#36A1D4;
  background-color:#36A1D4;
}

.clicked {
  animation: boxShadowAnim 0.5s forwards;
}

@keyframes boxShadowAnim {
  from {
    box-shadow: rgba(54, 161, 212, 1) 0px 0px 0px 0px;
  }
  to {
    box-shadow: rgba(54, 161, 212, 0) 0px 0px 0px 20px;
  }
}

.loading {
  display: none;
  position: absolute;
  background: aquamarine;
}

.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;

  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: #36A1D4;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 16px;
  text-align: center;
}
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
}

