@page {
    size: 210mm 297mm;
    /* Chrome sets own margins, we change these printer settings */
    margin: 27mm 16mm 27mm 16mm;
    -webkit-print-color-adjust: exact;
}

body
{
    /* this affects the margin on the content before sending to printer */
    margin: 0px;
}
.print-container {
    display: flex;
    flex: 1;
    height: 1120px;
}
.print-image {
   background-image: url("../gnPDF.jpeg");
    width: 950px;
    background-size: cover;
}

.print-map {
    position: absolute;
    top: 290px;
    left: 120px;
    width: 500px;
    height: 500px;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;

    }
    @page {
        size: 210mm 297mm;
        margin: 0;
    }
}
